import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TermsCondition from '../modules/TermsCondition/TermsCondition'

const termscondition = () => (
  <Layout>
    <SEO title="Terms of Service" />
    <TermsCondition />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
  </Layout>
)

export default termscondition