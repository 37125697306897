import React, { Component } from "react"
import { PrivacyPolicyBox, Content4, Privacypolicy } from "../../components/UI"
import { LandingScreen } from "../Home/Styled"
import { Link } from "gatsby"
import Signup from "../Home/signup"

class TermsCondition extends Component {
  render() {
    return (
      <div>
        <LandingScreen></LandingScreen>
        <div className="page_body1">
          <div className="career-heading"></div>
          <Privacypolicy>
            <PrivacyPolicyBox>
              <div className="privacy-heading">
                <b>Terms of Service</b>
              </div>

              <Content4>
                <br />
                <div style={{ textAlign: "justify" }}>
                  <p>
                    EFFECTIVE DATE: Oct 10, 2019
                    <br />
                    <br />
                    Welcome, and thank you for your interest in Korero
                    (“Korero”, “we,” or “us”), our web site at 
                    www.tekorero.com (the “Site”), and all related web sites
                    including our parent company’s (InnoverenIT Private Services
                    Limited (“InnoverenIT”)) website www.innoverenit.com
                    <br />
                    <br />
                    These Terms of Service along with Korero’s{" "}
                    <Link to="/privacypolicy/">
                      <u>Privacy Policy</u>
                    </Link>
                    , incorporated herein, constitute an agreement (“Agreement”)
                    by and between InnoverenIT (“us”, “we”, “our”,
                    “InnoverenIT”, or “Company”) and you, a registering customer
                    (“you”, “your”, “user(s)”, “Customer(s)”) of Korero, any
                    services, applications, widgets, mobile applications and
                    software made available by InnoverenIT.
                    <br />
                    <br />{" "}
                  </p>

                  <div
                    style={{
                      textAlign: "justify",
                      marginLeft: 5,
                      fontSize: 20,
                    }}
                  >
                    <b>PURPOSE OF AGREEMENT</b>
                  </div>
                  <br />
                  <p>
                    Korero’s Services are made available to Customer by
                    InnoverenIT and are subject to the following Terms of
                    Service (the "Terms") and {" "}
                    <Link to="/privacypolicy/">
                      <u>Privacy Policy</u>
                    </Link>{" "}
                    (“Privacy Policy”). InnoverenIT may also offer other
                    services under different terms of service.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>DESCRIPTION OF SERVICE(S)</b>
                    </div>
                    <br />
                    Korero provides Customer with access to an array of CRM
                    related services available via internet access to manage and
                    enhance internal and external business relationships
                    (“Service(s)”). Customer may connect to the Services using
                    any Internet browser. Customer is responsible for obtaining
                    access to the Internet and the equipment necessary to use
                    the Services. Customer is responsible for any fees, data
                    charges or surcharges that may be assessed by Customer’s
                    individual mobile data carrier incurred in connection with
                    Customer’s use of the Services.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>LIMITED LICENSE GRANT</b>
                    </div>
                    <br />
                    InnoverenIT hereby grants to Customer a nonexclusive,
                    nonassignable, nonsublicensable limited license, for
                    Customer’s use only, for the term of this Agreement, to
                    access and use the Services and any user’s guides,
                    specifications, and other related documentation available
                    online (the “Documentation”), subject to the terms and
                    conditions of this Agreement. The licenses granted herein
                    are conditioned upon timely payment in full, monthly or
                    annually, for the Services in advance of Customer’s access
                    to, continued use of or receipt of the Services.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>MODIFICATION OF TERMS OF SERVICE</b>
                    </div>
                    <br />
                    InnoverenIT may update, amend, modify, or supplement the
                    Terms at any time (“Updated Terms”). Updated Terms will be
                    posted on Korero’s website and InnoverenIT will use
                    reasonable efforts to notify Customer of any Updated Terms.
                    Customer will be provided the option to terminate Customer’s
                    use of the Services if InnoverenIT updates the Terms in a
                    manner that substantially affect Customer’s rights in
                    connection with use of the Services. Customer’s continued
                    use of the Services after posting of the Updated Terms on
                    InnoverenIT’s website will be deemed to be Customer’s
                    agreement to the updated Terms.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>CUSTOMER REGISTRATION OBLIGATIONS</b>
                    </div>
                    <br />
                    In consideration of Customer’s use of the Services, Customer
                    agrees to: (i) provide true, accurate, current and complete
                    information about Customer’s self, including but not limited
                    to contact information, as prompted by the Registration
                    Process (“Registration Data”) to sign up for a user account
                    (“Korero Account”), which may be either a single user
                    Individual Account or a multi user Account; and (ii)
                    maintain and promptly update Customer’s Registration Data to
                    keep it true, accurate, current and complete. If Customer
                    provides any information that is untrue, inaccurate,
                    outdated, or incomplete, or if InnoverenIT has reasonable
                    grounds to suspect that such information is untrue,
                    inaccurate, outdated, or incomplete, InnoverenIT has the
                    right to suspend or terminate Customer’s Korero Account and
                    refuse current or future use of any or all of the Services.
                    Customer may not use another person’s, entity’s, customer’s,
                    subscriber’s, company’s, or organization’s account without
                    the express prior written permission of that party. Customer
                    is solely responsible for maintaining the confidentiality of
                    Customer’s Korero Account and password and for restricting
                    access to Customer’s computer and/or mobile device, and
                    Customer agrees to accept responsibility for all activities
                    that occur under Customer’s Korero Account username or
                    password. If Customer has reason to believe that Customer’s
                    Korero Account is no longer secure, Customer agrees to
                    immediately notify InnoverenIT. Customer may be liable for
                    the losses incurred by InnoverenIT or others due to any
                    unauthorized use of Customer’s Korero Account.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>PROHIBITED ACTS & CUSTOMER RESPONSIBILITIES</b>
                    </div>
                    <br />
                    Customer is responsible for all activities occurring under
                    Customer’s Korero Account and shall abide by all applicable
                    local, state, national and foreign laws, treaties and
                    regulations in connection with Customer’s use of the
                    Services, including those related to data privacy,
                    international communications and the transmission of
                    technical or personal data. Customer is solely responsible
                    for Customer’s interactions with other subscribers to the
                    Services (“Subscribers”). Customer will not use Korero in a
                    manner or in connection with activity that violates a third
                    party’s rights or intellectual property. InnoverenIT
                    reserves the right, but has no obligation, to monitor
                    disputes between Customer and other Subscribers. InnoverenIT
                    reserves the right to remove any Customer or Subscriber from
                    the Service without a refund who violates these Terms of
                    Service, in the sole discretion of InnoverenIT. Customer
                    agrees and consents to transmitting their data, content,
                    Registration Data, Contact Information, and Confidential
                    Information onto public cloud and consents and agrees to
                    such data, content, Registration Data, Contact Information,
                    and Confidential Information to be stored in public cloud .
                    Customer will not upload or use in connection with Services
                    malicious code, malware, viruses, spyware, adware or bots.
                    Customer will not use, associate or link the Services in
                    connection with a website or content including any of the
                    following: (i) racial, ethnic, gender, age, religious,
                    political, or sexual orientation discrimination; (ii)
                    investment or business opportunities or advice prohibited by
                    law; (iii) obscenity, profanity, or pornography; (iv)
                    defamatory, abusive or threatening language, images or
                    content; (v) promotion of illegal substances or narcotics,
                    gambling, or other criminal conduct; (vi) pirating software
                    or media (vii) hacking, spoofing, phishing or phreaking. In
                    addition, Customer may not access the Services for purposes
                    of monitoring its performance or functionality, or for any
                    other competitive purposes. InnoverenIT shall have no
                    liability for Customer’s interactions with other
                    Subscribers, or for any other Subscriber’s action or
                    inaction.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>API TERMS</b>
                    </div>
                    <br />
                    Customer may access their data from the Services, via use of
                    the Application Program Interface (API). Any use of the
                    Korero API, including but not limited to access of the API
                    via a third party application is specifically bound and
                    subject to the terms and conditions herein. Without limiting
                    the foregoing, Customer specifically agrees to be bound to
                    the following terms regarding Korero API use:
                    <br />
                    a) CUSTOMER EXPRESSLY AGREES THE KORERO API WILL BE PROVIDED
                    ON AN “AS IS” AND “AS AVAILABLE” BASIS;
                    <br /> <br />
                    b) CUSTOMER EXPRESSLY AGREES THAT INNOVERENIT SHALL, IN NO
                    EVENT, BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL,
                    INDIRECT, SPECIAL, PUNITIVE, OR OTHER LOSS OR DAMAGE
                    WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS, LOSS OF DATA,
                    MISAPPROPRIATION OF DATA, BREACH OF DATA SECURITY, BUSINESS
                    INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS
                    INFORMATION, OR OTHER LOSS ARISING OUT OF OR CAUSED BY
                    CUSTOMER’S USE OF THE KORERO API, EITHER DIRECTLY OR VIA A
                    THIRD-PARTY APPLICATION.
                    <br /> <br />
                    c) INNOVERENIT EXPRESSLY RESERVES THE RIGHT AT ANY TIME, IN
                    ITS SOLE DISCRETION, TO REVOKE PERMISSION, MODIFY OR
                    DISCOUNTINUE THE AVAILABILITY OF THE KORERO API, IN WHOLE OR
                    IN PART, TO ANY CUSTOMER, WITH OR WITHOUT NOTICE.
                    <br /> <br />
                    d) ANY ABUSE OF THE KORERO API OR EXCESSIVE USE OF THE API
                    BANDWIDTH (TO BE DETERMINED IN THE SOLE DISCRECTION OF
                    INNOVERENIT) WILL RESULT IN THE IMMEDIATE SUSPENSION OF
                    CUSTOMER’S ACCOUNT WITHOUT NOTICE.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>PERSONAL INFORMATION AND PRIVACY</b>
                    </div>
                    <br />
                    Personal information, including Registration Data, that
                    Customer provides to Korero through the Services is governed
                    by the 
                    <Link to="/privacypolicy/">
                      <u>Privacy Policy</u>
                    </Link>
                     and applicable laws related to personal information.
                    Customer acknowledges and agrees that Korero aggregates all
                    Contact Information provided by Customer. Such Contact
                    Information is downloadable by Customer into a single file.
                    Customer’s election to use the Services each time Customer
                    logs on to Customer’s Korero Account functions as Customer’s
                    acceptance of the terms of the then-current 
                    <Link to="/privacypolicy/">
                      <u>Privacy Policy</u>
                    </Link>
                    . Customer acknowledges and Customer agrees that Customer is
                    responsible for maintaining the confidentiality of
                    Customer’s username, password and all other sensitive,
                    confidential or proprietary information relating to or used
                    in connection with Customer’s Korero Account. Customer
                    agrees that Customer is responsible for all activities that
                    occur in Customer’s Korero Account and Customer agrees to
                    notify InnoverenIT immediately of any unauthorized use of
                    Customer’s Korero Account. InnoverenIT is in no way
                    responsible for any loss or damage to Customer or to any
                    third party incurred as a result of any unauthorized access
                    and/or use of Customer’s Korero Account, or otherwise.
                    Customer hereby represents and warrants to InnoverenIT that
                    Customer owns or has all necessary right, title and interest
                    in and to the use of all information which Customer uses in
                    connection with Customer’s Korero Account and in the manner
                    in which such information is being used by Customer,
                    including but not limited to all Contact Information.
                    <br />
                    <br />
                    Customer’s Transferred Information shall be hosted and
                    stored by InnoverenIT on public cloud. In providing the
                    Services, InnoverenIT may engage sub-processors to process
                    Transferred Information, including, without limitation, any
                    associated Personal Information pursuant to this Agreement
                    within the European Economic Area, the United States and in
                    other countries and territories. Under no circumstances will
                    InnoverenIT be deemed a data controller with respect to
                    Transferred Information under the Data Protection Act
                    (European Directive 95/46/EC) or any relevant or replacement
                    law or regulation of any Member State as defined
                    therein. “Personal Information” means any Contact
                    Information processed by InnoverenIT pursuant to the
                    Agreement, relating to an identified or identifiable natural
                    person; where an “identifiable natural person” means an
                    individual who can be identified, directly or indirectly, in
                    particular by reference to an identification number or to
                    one or more factors specific to the individual’s physical,
                    physiological, mental, economic, cultural or social
                    identity.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>COMMUNICATIONS FROM INNOVERENIT</b>
                    </div>
                    <br />
                    The Services will include certain communications from
                    InnoverenIT, such as service announcements, administrative
                    messages and e-newsletters. Customer understands that these
                    communications sent to Customer shall be considered part of
                    using the Services. As part of our commitment to Customer’s
                    privacy, Customer may choose to not receive certain
                    promotional communications from InnoverenIT by updating
                    notifications preferences within Customer’s Korero Account.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>TRIALS, FEES, PAYMENTS, AND TAXES</b>
                    </div>
                    <br />
                    <b> Trials.</b> Customer may register for a free trial of
                    the Services and InnoverenIT will make the Services
                    available on a trial basis at no cost until the earlier of:
                    (i) the end of the trial period; or (ii) upon purchase of
                    any Premium Services by Customer. Customer may use the
                    Services offered for a free trial without any obligation to
                    purchase Premium Services. Customer may cancel Customer’s
                    Korero Account at any time during the trial period by
                    following the steps described in more detail in paragraph
                    Canceling and Terminating Korero’s Services. If Customer
                    does not purchase Premium Services or cancel the Korero
                    Account by the end of the trial period, Customer’s Korero
                    Account will be suspended and will be marked for deletion
                    from InnoverenIT servers. Customer may restore a suspended
                    Korero Account within sixty (60) calendar days of suspension
                    by purchasing Premium Services. If Customer’s Korero Account
                    remains in suspended status for sixty (60) calendar days,
                    all of Customer’s content and data, including but not
                    limited to Contact Information, will be deleted from
                    InnoverenIT servers. This information cannot be recovered
                    once deleted.
                    <br />
                    <br />
                    InnoverenIT will keep introducing new set of services,
                    modules and Value Adds that may not be necessarily part of
                    the core Korero subscription. Customer can enable their
                    users to access these services from the Users section, these
                    services will be billed separately as per information
                    available on website. For such additional services which are
                    not part of the subscription, only the admin of the Customer
                    has access to 1 month trial period, the other users do not
                    have access to any trial period.
                    <br />
                    <br />
                    <b> Paid Services.</b>  InnoverenIT reserves the right at
                    any time to assess fees for access, to portions of or in its
                    entirety, any or all of the Services and to modify such
                    fees. Customer may review Korero’s current pricing for Paid
                    Services at the following link 
                    <Link to="/pricing/">
                      <u>www.tekorero.com/pricing/</u>
                    </Link>
                    . Such fees shall not be charged unless Customer’s agreement
                    to pay such fees is obtained. If Customer purchases a paid
                    Korero Account that is offered by InnoverenIT for a fee or
                    adds additional services which are not part of the Korero
                    Account (“Value Adds”) and such transaction is confirmed by
                    Customer, Customer agrees to pay the applicable fees for the
                    Paid Services (including, without limitation, periodic fees
                    for upgrades and changes) as they become due plus all
                    related taxes, if applicable, and hereby authorizes
                    InnoverenIT to assess a monthly or annual recurring payment
                    transaction which will be assessed to the payment form
                    provided by Customer. If Customer elects not to pay such
                    fees, InnoverenIT shall have the right to cease providing
                    some or all of such Service to Customer. Customer agrees and
                    acknowledges that InnoverenIT’s payment processor, an
                    independent contractor, stores Customer’s payment
                    information.
                    <br />
                    <br />
                    <b> NO REFUNDS.</b>  InnoverenIT employs a NO REFUNDS
                    policy, regardless of use of the Services. ALL SALES ARE
                    FINAL. Customer agrees and acknowledges that Customer’s
                    obligation to pay fees continues through the end of the
                    billing period, monthly or annual, during which Customer
                    cancels Customer’s Premium Services.
                    <br />
                    <br />
                    <b>Past Due.</b>  If Customer’s Korero Account is in past
                    due status due to non-payment, InnoverenIT will notify
                    Customer in writing of account’s past due status. If payment
                    is not received by InnoverenIT within ten (10) calendar
                    days, Customer’s access to Customer’s Korero Account will be
                    restricted. If Customer’s Korero Account remains in past due
                    status for a period of thirty (30) calendar days, Customer’s
                    Korero Account will be suspended and will be marked for
                    deletion from InnoverenIT servers. Customer may restore a
                    suspended Korero Account within sixty (60) calendar days of
                    suspension by paying for the past due balance on the Korero
                    Account. If Customer’s Korero Account remains in suspended
                    status for sixty (60) calendar days, all of Customer’s
                    content and data, including but not limited to Contact
                    Information, will be deleted from InnoverenIT servers. This
                    information cannot be recovered once deleted.
                    <br />
                    <br />
                    <b>Payment Disputes.</b> All payment disputes must be
                    submitted in writing to InnoverenIT at support@tekorero.com,
                    within thirty (30) days of the occurrence of such disputed
                    charge. CUSTOMER WAIVES ALL CLAIMS ARISING IN CONNECTION TO
                    PAYMENTS CHARGED TO CUSTOMER’S ACCOUNT IF CUSTOMER FAILS TO
                    FILE A CLAIM WITH INNOVERENIT IN WRITING TO
                    SUPPORT@TEKORERO.COM WITHIN THIRTY (30) DAYS OF THE
                    OCCURRENCE OF SUCH DISPUTED CHARGE.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>CANCELING AND TERMINATING INNOVERENIT SERVICES</b>
                    </div>
                    <br />
                    Customer is solely responsible for properly canceling
                    Customer’s Korero Account with InnoverenIT. Customer should
                    email Korero’s team on <u>support@tekorero.com</u> for
                    cancellation. Upon cancellation of a InnoverenIT Account,
                    all of Customer’s content and data, including but not
                    limited to Contact Information, will be deleted from
                    InnoverenIT servers, suspended for maximum period of 60
                    days. <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>RESTRICTION OF USE</b>
                    </div>
                    <br />
                    In addition to all other terms and conditions of these
                    Terms, Customer shall not: (i) license, sublicense, sell,
                    resell, transfer, assign, distribute or otherwise
                    commercially exploit or make available to any third party
                    the Services in any way; (ii) copy, distribute, or disclose
                    any part of the Services in any medium; (iii) alter or
                    modify the Services in any way without the prior written
                    consent of InnoverenIT; (iv) reverse engineer, reverse
                    assemble, reverse compile or otherwise attempt to create the
                    source code from the Services, or interfere with, or
                    compromise the system integrity or security of, or decipher
                    any transmissions to or from the servers running the
                    Services; (v) take any action that imposes, or may impose at
                    our sole discretion an unreasonable or disproportionately
                    large load on our infrastructure; (vi) upload invalid data,
                    viruses, worms, Trojan horses, or other harmful or
                    disruptive codes, components, devices, or software agents
                    through the Services; (vii) impersonate another person or
                    otherwise misrepresent Customer’s affiliation with a person
                    or entity, conducts fraud, hide or attempt to hide
                    Customer’s identity, or misrepresent the source or content
                    of information transmitted through the Services; (viii) use
                    the Services in any unlawful manner or in any other manner
                    that could damage, disable, overburden or impair the
                    InnoverenIT website or all or any portion of the Services;
                    (ix) bypass the measures InnoverenIT may use to prevent or
                    restrict access to the Services; (x) use any robot, spider,
                    scraper, script, or other comparable automated technology to
                    access the Services for any purpose without InnoverenIT’s
                    express written permission; (xi) gain unauthorized access to
                    the Services, its user accounts, computer systems or
                    networks through unauthorized means such as hacking or
                    password mining; (xii) collect or harvest any personally
                    identifiable information, including account names, from the
                    Services without first obtaining the express prior written
                    permission of such party to use any such personally
                    identifiable information; (xiii) use third party links to
                    sites without agreeing to their website terms & conditions;
                    and (xiv) post links to third party sites or use their logo,
                    company name, etc. in connection with the Services without
                    first obtaining their express prior written permission.
                    Customer shall: (i) notify InnoverenIT immediately of any
                    unauthorized use of any password or InnoverenIT Account or
                    any other known or suspected breach of security; (ii) report
                    to InnoverenIT immediately and use reasonable efforts to
                    stop immediately any copying or distribution of content that
                    is known or suspected by Customer or other Subscribers to
                    violate these Terms or the intellectual property rights of
                    third parties; and (iii) not impersonate another Subscriber
                    or provide false identity information to gain access to or
                    use the Services.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>SPAMMING AND ILLEGAL ACTIVITIES</b>
                    </div>
                    <br />
                    Customer agrees Customer is solely responsible for the
                    contents of Customer’s transmissions through the Services.
                    Customer agrees not to use the Services for illegal purposes
                    or for the transmission of material that is unlawful,
                    harassing, libelous, invasive of another person’s privacy,
                    abusive, threatening, harmful, vulgar, pornographic,
                    obscene, or otherwise objectionable, contains viruses, or
                    infringes or may infringe the intellectual property or other
                    proprietary rights of another person. Customer agrees not to
                    use the Services for the transmission of “junk mail”,
                    “spam”, “chain letters”, or other unsolicited mass
                    distribution of email or other unauthorized or unsolicited
                    activity. InnoverenIT reserves the right to terminate
                    Customer’s access to the Services if InnoverenIT believes
                    that Customer has used the Services for any illegal or
                    unauthorized activity.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>DATA OWNERSHIP</b>
                    </div>
                    <br />
                    We respect Customer’s right to exclusive ownership of
                    Customer’s published and stored content in the Korero
                    Account, including but not limited to contact names, tasks,
                    events, messages in the Korero Account and posted to social
                    networks, and related content created or stored by Customer
                    and any type of data that identifies Customer or any
                    specific customer or program, including but not limited to
                    information about usage, account information, internal team
                    members, or merchants in conjunction with Customer or
                    Customer’s customers, Contact Information (the “Confidential
                    Information”). Unless specifically permitted by Customer,
                    Customer’s use of the Services does not grant InnoverenIT
                    the license to use, reproduce, adapt, modify, publish or
                    distribute the Confidential Information content created by
                    Customer or stored in Customer’s Korero Account for
                    InnoverenIT’s commercial, marketing or any similar purpose.
                    Customer expressly grants InnoverenIT the right to use and
                    analyze aggregate system activity data associated with use
                    of the Services by Customer and other subscribers for the
                    purposes of optimizing, improving or enhancing the way the
                    Services operate, and to create new features and
                    functionality in connection with the Services in the sole
                    discretion of InnoverenIT.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>INTELLECTUAL PROPERTY RIGHTS</b>
                    </div>
                    <br />
                    Korero, both the word and mark, and the logos are trademarks
                    of InnoverenIT. Customer agrees not to display or use, in
                    any manner, the InnoverenIT trademarks, without
                    InnoverenIT’s express prior written consent. Customer
                    acknowledges and agrees that InnoverenIT is and shall remain
                    the exclusive owner of all right, title and interest in and
                    to the Services, including without limitation all patent,
                    copyright, trade secret, trademark and other intellectual
                    property rights therein, including any optimizations,
                    enhancements, modifications or additional features added
                    from time to time by InnoverenIT, whether in connection with
                    Customer’s use or feedback or otherwise. Except for the
                    right to use the Services strictly in accordance with these
                    Terms, as amended or modified from time to time, no license
                    or conveyance of any such proprietary rights to Customer is
                    granted or implied under these Terms.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>THIRD PARTY INTERACTIONS</b>
                    </div>
                    <br />
                    During Customer’s use of the Services, Customer may enter
                    into correspondence with, purchase goods and/or services
                    from, or participate in promotions of third parties
                    displaying their goods and/or services through the Services.
                    Any such activity, and any terms, conditions, warranties or
                    representations associated with such activity, is solely
                    between Customer and the applicable third-party and is
                    governed by any terms, rules, regulations and policies of
                    such third party. InnoverenIT and its licensors shall have
                    no liability, obligation or responsibility for any such
                    correspondence, purchase or promotion between Customer and
                    any such third-party. InnoverenIT and its licensors shall
                    not be responsible for interruptions in service or
                    performance from third parties, or any interruptions in the
                    Services caused by such third party services. InnoverenIT
                    expressly does not endorse any sites on the Internet that
                    are linked through the Services, and in no event shall
                    InnoverenIT or its licensors be responsible for any content,
                    products, or other materials on or available from such
                    third-party sites. InnoverenIT provides the Services to
                    Customer pursuant to the terms and conditions of these
                    Terms. Customer recognizes, however, that certain
                    third-party providers of ancillary software, hardware or
                    services may require Customer’s agreement to additional or
                    different license or other terms prior to Customer’s use of
                    or access to such software, hardware or services.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>CUSTOMER ACCESS</b>
                    </div>
                    <br />
                    By agreeing to these Terms, Customer will be provided access
                    to the Services on the terms and conditions outlined herein
                    from time to time. If necessary, InnoverenIT reserves the
                    right to deny Customer’s access to the Services from time to
                    time in order to permit InnoverenIT to perform routine or
                    emergency maintenance, bug fixes or upgrades, in which
                    event, Customer authorizes InnoverenIT to access Customer’s
                    Korero Account. InnoverenIT shall use commercially
                    reasonable efforts to notify Customer of anticipated
                    material disruptions in the Services and shall make
                    commercially reasonable efforts to schedule such activities
                    at non-peak user hours. In addition, InnoverenIT reserves
                    the right, in its sole discretion, to reassign or rename
                    Customer’s domain/profile URL. InnoverenIT is not
                    responsible for outages or service degradations where
                    systems, programs, data, or processes that are controlled,
                    supplied or operated by Customer are contributing factors to
                    the outage or service degradation. In addition, where the
                    Services are Internet-based, Customer acknowledges and
                    agrees that InnoverenIT cannot control the flow of data
                    between its servers, other portions of the Internet and
                    Customer’s connections and computers. Such flow depends in
                    large part on the performance of Internet services provided
                    or controlled by third parties. At times, actions or
                    inactions caused by these third parties can impair
                    Customer’s connections to the Internet. Although InnoverenIT
                    will use its commercially reasonable efforts to take actions
                    it deems appropriate to remedy and avoid such events, it
                    cannot guarantee that they will not occur.
                    <br />
                    <br />
                    <b>
                      {" "}
                      DISCLAIMER OF WARRANTIES
                      <br />
                      CUSTOMER EXPRESSLY UNDERSTANDS, ACKNOWLEDGES AND AGREES
                      THAT THE USE OF THE SERVICES IS AT CUSTOMER’S SOLE RISK
                      AND LIABILITY. THE SERVICES ARE PROVIDED ON AN
                      AS-IS-AND-AS-AVAILABLE BASIS. INNOVERENIT EXPRESSLY
                      DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, WHETHER
                      EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
                      IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                      PARTICULAR PURPOSE, AND NON-INFRINGEMENT. INNOVERENIT
                      MAKES NO WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED,
                      TIMELY, SECURE, OR VIRUS FREE. USE OF ANY MATERIALS
                      DOWNLOADED OR OBTAINED THROUGH THE USE OF THE SERVICES
                      SHALL BE AT CUSTOMER’S OWN DISCRETION AND RISK AND
                      CUSTOMER WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
                      CUSTOMER’S COMPUTER SYSTEM, MOBILE TELEPHONE, WIRELESS
                      DEVICE OR DATA THAT RESULTS FROM THE USE OF THE SERVICES
                      OR THE DOWNLOAD OF ANY SUCH MATERIALS. NO ADVICE OR
                      INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY CUSTOMER
                      FROM INNOVERENIT, ITS EMPLOYEES OR REPRESENTATIVES SHALL
                      CREATE ANY WARRANTY NOT OTHERWISE EXPRESSLY STATED IN
                      THESE TERMS.
                      <br />
                      LIMITATION OF LIABILITY
                      <br />
                      CUSTOMER AGREES THAT INNOVERENIT SHALL, IN NO EVENT, BE
                      LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT,
                      SPECIAL, PUNITIVE, OR OTHER LOSS OR DAMAGE WHATSOEVER OR
                      FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION,
                      COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION, OR OTHER
                      LOSS ARISING OUT OF OR CAUSED BY CUSTOMER’S USE OF OR
                      INABILITY TO USE THE SERVICES, EVEN IF INNOVERENIT HAS
                      BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                      CUSTOMER’S SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH
                      INNOVERENIT RELATED TO ANY OF THE SERVICES SHALL BE
                      TERMINATION OF SUCH SERVICES. IN NO EVENT SHALL
                      INNOVERENIT'S LIABILITY TO CUSTOMER, WHETHER DIRECT OR
                      INDIRECT, EXCEED THE FEES ACTUALLY PAID BY CUSTOMER TO
                      INNOVERENIT FOR SUCH SERVICES IN THE SIX (6) MONTHS
                      IMMEDIATELY PRECEDING THE EVENTS GIVING RISE TO THE CLAIM.
                      CUSTOMER AGREES THAT THE FOREGOING IS AN AGREED ALLOCATION
                      OF RISK AND IS A REFLECTION OF THE RIGHTS AND OBLIGATIONS
                      AGREED UPON BY CUSTOMER AND INNOVERENIT IN THESE TERMS.
                    </b>
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>INDEMNIFICATION</b>
                    </div>
                    <br />
                    Customer agrees to indemnify and hold harmless InnoverenIT,
                    its officers, directors, employees, suppliers, and
                    affiliates, from and against any losses, damages, fines and
                    expenses (including attorney’s fees and costs) arising out
                    of or relating to any claims that Customer has used the
                    Services in violation of another party’s rights, in
                    violation of any law, in violation of any provisions of the
                    Terms, or in connection with any other claim related to
                    Customer’s use of the Services.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>ARBITRATION</b>
                    </div>
                    <br />
                    Any controversy or claim arising out of or relating to the
                    Terms shall be settled by binding arbitration in accordance
                    with the commercial arbitration rules of the Indian Council
                    of Arbitration. Any such controversy or claim shall be
                    arbitrated on an individual basis, and shall not be
                    consolidated in any arbitration with any claim or
                    controversy of any other party. The decision of the
                    arbitrator shall be final and may not be appealed. The
                    arbitration shall be conducted in Bhubaneswar, India only.
                    Notwithstanding anything to the contrary, InnoverenIT may at
                    any time seek injunctions or other forms of equitable relief
                    from any court of competent jurisdiction.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>TERMINATION</b>
                    </div>
                    <br />
                    Customer agrees that InnoverenIT may terminate Customer’s
                    Korero’s Account and access to the Services for reasons
                    including, but not be limited to, breaches or violations of
                    these Terms or the 
                    <Link to="/privacypolicy/">
                      <u>Privacy Policy</u>
                    </Link>
                    , a request by Customer to terminate Customer’s Korero
                    Account, discontinuance or material modification to the
                    Services, unexpected technical issues or problems, extended
                    periods of inactivity and/or requests by law enforcement or
                    other government agencies, and for non-payment for Paid
                    Services. Termination of Customer’s Korero Account includes
                    elimination of access to the Service, deletion of Customer’s
                    Korero Account, deletion of all of Customer’s content and
                    data, including but not limited to Contact Information, as
                    permitted or required by law.
                    <br />
                    <br />
                    <div
                      style={{
                        textAlign: "justify",
                        marginLeft: 5,
                        fontSize: 20,
                      }}
                    >
                      <b>MISCELLANEOUS</b>
                    </div>
                    <br />
                    <b> Assignability.</b> Customer may not assign or transfer
                    this Agreement, in whole or in part, by operation of law or
                    otherwise, or its rights or obligations hereunder without
                    InnoverenIT’s express prior written consent. Any attempt to
                    assign or transfer this Agreement, without such consent,
                    will be nullified and of no effect. Subject to the
                    foregoing, this Agreement will bind and inure to the benefit
                    of each party's permitted successors and assigns.
                    <br />
                    <br />
                    <b> Governing Law and Jurisdiction.</b> This Agreement will
                    be governed by and construed in accordance with the laws of
                    India excluding that body of laws known as conflicts of law.
                    <br />
                    <br />
                    <b>Severability.</b>If for any reason a court of competent
                    jurisdiction finds any provision of this Agreement invalid
                    or unenforceable, that provision of the Agreement will be
                    enforced to the maximum extent permissible and the other
                    provisions of this Agreement will remain in full force and
                    effect.
                    <br />
                    <br />
                    <b>Waiver.</b> The failure by either party to enforce any
                    provision of this Agreement will not constitute a waiver of
                    future enforcement of that or any other provision. Except as
                    expressly set forth in this Agreement, the exercise by
                    either party of any of its remedies under this Agreement
                    will be without prejudice to its other remedies under this
                    Agreement or otherwise.
                    <br />
                    <br />
                    <b>Notices.</b>  Any notices or other communications
                    provided under this Agreement will be given: (i) via email;
                    or (ii) by posting such notice on Korero’s website or to the
                    Services. For notices given by email, the date of receipt
                    will be deemed the date on which such notice is transmitted.
                    <br />
                    <br />
                    <b>Force Majeure.</b>Neither party will be responsible for
                    any failure or delay in its performance under this Agreement
                    (except for any payment obligations) due to causes beyond
                    its reasonable control, including, but not limited to, labor
                    disputes, strikes, lockouts, shortages of or inability to
                    obtain energy, raw materials or supplies, war, terrorism,
                    riot, or acts of God.
                    <br />
                    <br />
                    <b>ACCEPTANCE</b>
                    <br />
                    BY CLICKING THE "ACCEPT" BUTTON OR CHECKING THE "ACCEPT" BOX
                    OR OTHER MEANS PROVIDED FOR ACCEPTANCE YOU: (i) REPRESENT
                    THAT YOU ARE DULY AUTHORIZED BY CUSTOMER TO ACCESS AND USE
                    THE SERVICES; AND (ii) ACCEPT THESE CUSTOMER TERMS OF
                    SERVICE AND AGREE THAT YOU ARE LEGALLY BOUND BY THEM. IF YOU
                    DO NOT AGREE TO THESE TERMS OF USE, DO NOT CLICK THE
                    "ACCEPT" BUTTON OR CHECK THE "ACCEPT" BOX OR OTHER MEANS
                    PROVIDED FOR ACCEPTANCE AND YOU WILL HAVE NO LICENSE TO, AND
                    MUST NOT ACCESS OR USE, THE SERVICE.
                    <br />
                    <br />
                  </p>
                </div>
              </Content4>
            </PrivacyPolicyBox>
          </Privacypolicy>
        </div>
        <div className="policycontainer-3">
          <Signup />
        </div>
      </div>
    )
  }
}

export default TermsCondition
